import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from "element-ui";

Vue.use(VueRouter)
const routes = [
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/Register.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/Login.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../components/ForgotPassword.vue')
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: () => import('../components/Deposit.vue')
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () => import('../components/Withdrawal.vue')
    },
    {
        path: '/transaction',
        name: 'transaction',
        component: () => import('../components/Transaction.vue')
    },
    {
        path: '/debug-tools',
        name: 'debug-tools',
        component: () => import('../components/DebugTools.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../components/Logout.vue')
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 导航守卫，前置处理
router.beforeEach((to, from, next) => {
    let isAuthenticated = !!sessionStorage.getItem('userInfo')
    // 如果路由要跳转到除了登录和注册的界面的话就判断是否已经登录，如果没有登录就强制跳到登录界面
    if (to.path !== '/login' && to.path !== '/register' && !isAuthenticated) {
        // 还没登入
        next({path: '/login'})
        Message({
            message: 'Please Login！',
            type: "warning",
        });
    } else if ((to.path === '/login' || to.path === '/register') && isAuthenticated) {
        // 已经登入
        next({path: '/deposit'})
        Message({
            message: 'Login already！',
            type: "warning",
        });
    } else next()
})

export default router
