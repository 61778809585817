<template>
  <div class="vue-tempalte">
    <!-- Navigation -->
    <nav class="navbar shadow bg-white rounded justify-content-between flex-nowrap flex-row fixed-top">
      <div class="container">
        <a class="navbar-brand float-left" href="/deposit">
          Mock Client Site
        </a>
        <ul class="nav navbar-nav flex-row float-right">
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/login">Login</router-link>
          </li>
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/register">Register</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/deposit">Deposit</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/withdrawal">Withdrawal</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/transaction">Transaction</router-link>
          </li>
<!--          <li class="nav-item" v-if="isAuthenticated">-->
<!--            <router-link class="btn btn-outline-primary" to="/debug-tools">Debug Tools</router-link>-->
<!--          </li>-->
          <li class="nav-item" v-if="isAuthenticated">
            <button class="btn btn-link" @click="fetchBalance">
              Balance: {{ userBalance.toFixed(2) }}
              <i class="fas fa-sync-alt"></i>
            </button>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="btn btn-outline-primary" to="/logout">Logout</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Main -->
    <div class="App">
      <div class="vertical-center">
        <div class="inner-block">
          <router-view @loginemit="login" @logoutemit="logout"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, reactive, watchEffect} from 'vue';
// import axios from 'axios';
import axiosInstance from "@/components/axios/axios";

console.log('VUE_APP_ENV', process.env.VUE_APP_ENV);

// Create a reactive object to store the authentication state and user balance
const state = reactive({
  userInfo: JSON.parse(sessionStorage.getItem('userInfo') || '{}')
});

// Define the computed property to determine if the user is authenticated
const isAuthenticated = computed(() => !!state.userInfo.username);

// Define the computed property to get the user balance
const userBalance = computed(() => state.userInfo.balance || 0);

// Watch for changes in sessionStorage and update the reactive state
watchEffect(() => {
  state.userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
});

// Function to fetch the latest balance from the API
async function fetchBalance() {
  try {
    const response = await axiosInstance.post('/api/checkBalance/checkClientBalance', {
      username: state.userInfo.username,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        //   Authorization: `Bearer ${state.userInfo.token}`
      }
    });
    console.log('response', response);
    sessionStorage.setItem("userInfo", JSON.stringify(response.data.data));
    login(); // trigger auto refresh by change reactive component

  } catch (error) {
    console.error('Error fetching balance:', error);
  }
}

function login() {
  state.userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
}
function logout() {
  state.userInfo = '{}';
}
</script>

<style>
/* Add any styles here if needed */
</style>
